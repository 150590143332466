import React from 'react'
import { Link } from 'gatsby'
import Menu from '../Board-Menu/menu'
import '../Side-bar/side-bar.css'
import { ThemeToggler } from 'gatsby-plugin-dark-mode'

const LiStyle = {
  listStyle: 'none',
}

const UlStyle = {
  marginLeft: '0px',
}

const SideBarFr = ({ siteTitle }) => (
  <div className="sidebar-wrapper">
    <ThemeToggler>
      {({ theme, toggleTheme, isOn, handleToggle }) => (
        <>
          <input
            checked={theme === 'dark'}
            onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
            className="react-switch-checkbox"
            id={`react-switch-new`}
            type="checkbox"
          />
          Dark Mode
          <label
            style={{ background: isOn && onColor }}
            className="react-switch-label"
            htmlFor={`react-switch-new`}
          >
            <span className={`react-switch-button`}>
              <div className="sun_icon" />
            </span>
          </label>
        </>
      )}
    </ThemeToggler>
    <hr />
    <div className="community">
      <h5 className="text_upper"> Statistiques nationales</h5>
      <ul style={UlStyle}>
        <li style={LiStyle}>
          <Link
            activeStyle={{
              color: '#CF2126',
              textDecoration: 'underline dotted #000000CC',
              textUnderlinePosition: 'under',
              fontWeight: 'bold',
              textDecoration: 'var(--textDecor)',
            }}
            to={'/en-CA'}
          >
            National English
          </Link>
        </li>
        <li style={LiStyle}>
          <Link
            activeStyle={{
              color: '#CF2126',
              textDecoration: 'underline dotted #000000CC',
              textUnderlinePosition: 'under',
              fontWeight: 'bold',
              textDecoration: 'var(--textDecor)',
            }}
            to="/fr-CA"
          >
            National Fran&ccedil;ais
          </Link>
        </li>
      </ul>
      <br />
      <Menu />
      <br />
      {/* <Menu /> */}
    </div>
    <h5 className="text_upper"> STATISTIQUES SUR LE MARCHÉ DU LOGEMENT</h5>
    <ul>
      <li style={LiStyle}>
        <a href="http://www.crea.ca/fr/housing-market-stats/mls-home-price-index/">
          Indice des prix des propriétés MLS®
        </a>
      </li>
      <ul className="children">
        <li style={LiStyle}>
          <a href="http://www.crea.ca/fr/housing-market-stats/mls-home-price-index/about-hpi/">
            À propos de l’IPP MLS®
          </a>
        </li>
        <li style={LiStyle}>
          <a href="http://www.crea.ca/fr/housing-market-stats/mls-home-price-index/getting-started/">
            Quels avantages me procure l’IPP MLS®?
          </a>
        </li>
        <li style={LiStyle}>
          <a href="http://www.crea.ca/fr/housing-market-stats/mls-home-price-index/hpi-tool/">
            Try the MLS® HPI Tool
          </a>
        </li>
        <li style={LiStyle}>
          <a href="http://www.crea.ca/fr/housing-market-stats/mls-home-price-index/resources/">
            Documentation
          </a>
        </li>
      </ul>
    </ul>

    <ul>
      <h5 className="text_upper">Statistiques nationales</h5>
      <ul className="children ntl_stats">
        <li style={LiStyle}>
          <Link
            activeStyle={{
              color: '#CF2126',
              textDecoration: 'underline dotted #000000CC',
              textUnderlinePosition: 'under',
              fontWeight: 'bold',
              textDecoration: 'var(--textDecor)',
            }}
            to="/fr-CA/stats/2WzwJAqfKd3QekqmJcKmli/"
          >
            À propos de
          </Link>
        </li>
        <li style={LiStyle}>
          <Link
            activeStyle={{
              color: '#CF2126',
              textDecoration: 'underline dotted #000000CC',
              textUnderlinePosition: 'under',
              fontWeight: 'bold',
              textDecoration: 'var(--textDecor)',
            }}
            to="/fr-CA/stats/3Yj8RUXHGUebU2k1UfYnqT/"
          >
            Tendances emploi
          </Link>
        </li>
        <li style={LiStyle}>
          <Link
            activeStyle={{
              color: '#CF2126',
              textDecoration: 'underline dotted #000000CC',
              textUnderlinePosition: 'under',
              fontWeight: 'bold',
              textDecoration: 'var(--textDecor)',
            }}
            to="/fr-CA/stats/ZtDiHSNyWRJYz57YMk7aX/"
          >
            Taux d'intérêt
          </Link>
        </li>
        <li style={LiStyle}>
          <Link
            activeStyle={{
              color: '#CF2126',
              textDecoration: 'underline dotted #000000CC',
              textUnderlinePosition: 'under',
              fontWeight: 'bold',
              textDecoration: 'var(--textDecor)',
            }}
            to="/fr-CA/stats/2k7dZil1MPa7B2vbqlNKlH/"
          >
            Confiance des consommateurs
          </Link>
        </li>
        <li style={LiStyle}>
          <Link
            activeStyle={{
              color: '#CF2126',
              textDecoration: 'underline dotted #000000CC',
              textUnderlinePosition: 'under',
              fontWeight: 'bold',
              textDecoration: 'var(--textDecor)',
            }}
            to="/fr-CA/stats/7MELNRD8vrte7Qywn37pc4/"
          >
            Migration au Canada
          </Link>
        </li>
      </ul>
    </ul>
    <ul>
      <li style={LiStyle}>
        <a href="https://www.crea.ca/fr/analyses-du-marche-de-lhabitation/statistiques-sur-le-marche-de-lhabitation-canadien/previsions-trimestrielles/">
          Prévisions trimestrielles
        </a>
      </li>
      <li style={LiStyle}>
        <a href="https://www.crea.ca/fr/housing-market-stats/national-price-map/">
          Carte du prix national
        </a>
      </li>
    </ul>
    <br />
    <div className="widgets widget_1">
      <h2>
        POURQUOI FAIRE APPEL AUX SERVICES D'UN COURTIER OU AGENT IMMOBILIER?
      </h2>
      <p>
        Que vous achetiez ou vendiez, une transaction immobilière peut être
        compliquée. Un courtier ou agent immobilier peut vous simplifier la
        vie...
        <a href="http://www.crea.ca/fr/buyers-or-sellers/why-a-realtor/">
          Informez-vous ›
        </a>
      </p>
    </div>
    <div className="widgets widget_2">
      <h2>COMMENT L’ACI PEUT VOUS AIDER</h2>
      <p>
        L’ACI procure des avantages aux propriétaires canadiens...
        <a href="https://www.crea.ca/fr/a-propos/notre-mission/">
          Informez-vous ›
        </a>
      </p>
    </div>
    <div className="widgets widget_3">
      <h2>OBTENEZ DES RENSEIGNEMENTS ET STATISTIQUES</h2>
      <p>
        L’ACI compile et analyse plusieurs facteurs touchant le marché
        immobilier pour le public, les courtiers ou agents immobiliers et les
        gouvernements...
        <a href="http://www.crea.ca/fr/housing-market-stats/">
          {' '}
          Informez-vous ›
        </a>
      </p>
    </div>
  </div>
)

export default SideBarFr
