import React from 'react'
import MenuFR from '../Board-Menu/menu'

const menu = {
  fontSize: '16px',
  textAlign: 'left',
  color: 'white',
}

export default ({ close }) => (
  <div className="menu burgerMenu">
    <div className="community">
      <MenuFR />
    </div>
    <ul style={menu}>
      <li onClick={close}>
        <a href="/fr-CA/stats/2WzwJAqfKd3QekqmJcKmli/">Accueil</a>
      </li>
      <li onClick={close}>
        <a href="/fr-CA/stats/2WzwJAqfKd3QekqmJcKmli/">À propos de nous</a>
      </li>
      <li onClick={close}>
        <a href="/fr-CA/stats/3Yj8RUXHGUebU2k1UfYnqT/">Tendances emploi</a>
      </li>
      <li onClick={close}>
        <a href="/fr-CA/stats/ZtDiHSNyWRJYz57YMk7aX/">Taux d'intérêt</a>
      </li>
      <li onClick={close}>
        <a href="/fr-CA/stats/2k7dZil1MPa7B2vbqlNKlH/">
          Confiance des consommateurs
        </a>
      </li>
      <hr />
      <li onClick={close}>
        <a href="https://www.crea.ca/fr/analyses-du-marche-de-lhabitation/statistiques-sur-le-marche-de-lhabitation-canadien/previsions-trimestrielles/">
          Prévisions trimestrielles
        </a>
      </li>
      <li onClick={close}>
        <a href="https://www.crea.ca/fr/housing-market-stats/national-price-map/">
          Carte du prix national
        </a>
      </li>
      <hr />

      <li onClick={close}>
        <a href="https://www.crea.ca/fr/defense-des-interets/defense-des-interets-politiques/">
          Défense des intérêts politiques
        </a>
      </li>
      <li onClick={close}>
        <a href="https://www.crea.ca/fr/technologie/realtor-ca-au-service-de-la-population-canadienne/">
          Technologie pour nos membres et les consommateurs
        </a>
      </li>
      <li onClick={close}>
        <a href="https://www.crea.ca/federal-affairs/creas-governmental-efforts/">
          Normes et programmes
        </a>
      </li>
      <li onClick={close}>
        <a href="https://www.crea.ca/fr/analyses-du-marche-de-lhabitation/statistiques-sur-le-marche-de-lhabitation-canadien/">
          Analyses du marché de l’habitation
        </a>
      </li>
      <li onClick={close}>
        <a href="http://www.realtorlink.ca/" target="_blank">
          Centre des médias
        </a>
      </li>
      <hr />

      <li onClick={close}>
        <a href="https://www.crea.ca/fr">ACI.CA</a>
      </li>
      <li onClick={close}>
        <a href="https://www.creacafe.ca/fr/">Blogue de l'ACI</a>
      </li>
      <li onClick={close}>
        <a href="https://www.realtorscare.ca/fr/" target="_blank">
          REALTOR CARE<sup>®</sup>
        </a>
      </li>
      <li onClick={close}>
        <a href="https://www.realtorscare.ca/" target="_blank">
          REALTOR.CA
        </a>
      </li>
      <hr />
      <li onClick={close}>
        <a href="https://www.realtorlink.ca/content/realtorlink/crea/fr_CA.html">
          Accès membre
        </a>
      </li>
      <li onClick={close}>
        <a href="https://www.crea.ca/fr/carrieres/">Carrières</a>
      </li>
      <li onClick={close}>
        <a href="https://www.crea.ca/fr/pour-nous-joindre/">Nous joindre</a>
      </li>
      <li onClick={close}>
        <a href="/en-CA/">EN</a>
      </li>
    </ul>
  </div>
)
