import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Button } from 'reactstrap'
import Helmet from 'react-helmet'
import * as PropTypes from 'prop-types'
import LayoutFr from '../components/Layouts/layout.fr'
import '../components/Side-bar/side-bar.css'
import Chart from '../components/Chart/national.charts_fr'
import SidebarFr from '../components/Side-bar/side-bar.fr'
import Footersub from '../components/Footer/footer-sub-fr'
import footerStyle from '../components/Footer/footer.module.css'
import ChartA from '../components/Chart/Natl_chartA.fr'
import ChartB from '../components/Chart/Natl_chartB.fr'
import '../components/Content/content.css'
import PopupData from '../components/PopupData/popupdata_fr'
import PopupDataHPI from '../components/PopupData/popupdata_hpi_fr'
import NewChart from '../components/Chart/nrs.charts.fr'
import StatsVideo from '../components/Stats video/stats-video'
const propTypes = {
  data: PropTypes.object.isRequired,
}

const style = {
  marginBottom: '20px',
}

class RootDEIndex extends React.Component {
  getYear() {
    return new Date().getFullYear()
  }
  constructor(props) {
    super(props)

    this.state = {
      dd1: false,
      modal1: false,
      modal2: false,
    }
    this.dropdownToggle = this.dropdownToggle.bind(this)
  }

  dropdownToggle() {
    this.setState({
      dd1: !this.state.dd1,
    })
  }
  closeModal(tabId) {
    this.setState({
      [tabId]: false,
    })
  }
  showModal = () => {
    this.setState({
      chartA: !this.state.chartA,
    })
  }
  showModal2 = () => {
    this.setState({
      chartB: !this.state.chartB,
    })
  }
  topFunction() {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handeleScroll)
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handeleScroll)
  }
  handeleScroll() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('myBtn').style.display = 'block'
    } else {
      document.getElementById('myBtn').style.display = 'none'
    }
  }
  render() {
    const natl = get(this, 'props.data.fr.edges[0].node')
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')

    return (
      <LayoutFr data={this.props.data} location={this.props.location}>
        <Helmet title={`${natl.title} | ${siteTitle}`} />
        {/* <Chart /> */}
        <div className="content-wrapper">
          <div className="row split">
            <div className="col-md">
              <div className="entry-header">
                <h1>Statistiques nationales</h1>
              </div>
              <div className="entry-content">
                <div className="">
                  <h2>{natl.headline}</h2>

                  <div>

                    <div className="mainFloatContainer">
                      <div className="LeftFloat ">
                        {/*    <div style={{ marginBottom: '20px' }}>
                          <h3>
                            <b>Stats video FR</b>{' '}
                          </h3>
                          <StatsVideo />
                        </div> */}
                        <div>
                          {/*  <Chart style={style} /> */}
                          <NewChart style={style} />
                        </div>
                        <div style={{ textAlign: 'center' }}>
                          <PopupData />
                        </div>
                      </div>

                      <div className="RightFloat ">
                        {natl.contentB !== null && (
                          <div
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html:
                                natl.contentB.childContentfulRichText.html,
                            }}
                          />
                        )}
                        {natl.contentA !== null && (
                          <div
                            className="content entry-sub"
                            dangerouslySetInnerHTML={{
                              __html: natl.contentA.childContentfulRichText.html,
                            }}
                          />
                        )}

                        {natl.chartA !== null && (
                          <div className="chart_container">
                            <div className="imageBox">
                              {/*    <img
                            onClick={this.showModal}
                            src={natl.chartA.fixed.src}
                            className="thumbnail"
                          /> */}
                              <ChartA className="thumbnail" />
                            </div>

                            <p>Graphique A</p>
                          </div>
                        )}
                        {natl.contentC !== null && (
                          <div
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html:
                                natl.contentC.childContentfulRichText.html,
                            }}
                          />
                        )}

                        {natl.chartB !== null && (
                          <div className="chart_container">
                            <div className="imageBox">
                              {/*   <img
                            onClick={this.showModal}
                            src={natl.chartB.fixed.src}
                            className="thumbnail"
                          /> */}
                              <ChartB className="thumbnail" />
                            </div>
                            <p>Graphique B</p>
                          </div>
                        )}
                        {natl.contentD !== null && (
                          <div
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html:
                                natl.contentD.childContentfulRichText.html,
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {/* <div className="sheet ">
                      <SheetFR />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <Button
              onClick={this.topFunction}
              id="myBtn"
              title="Go to top"
              className="scroll-btn"
            >
              <img
                src={require('../images/btn_backtop.png')}
                alt="Scroll to top"
                title="Scroll to top"
              />
            </Button>

            <div className="col-sm- side-content mobile-style">
              <div className="chart">
                <hr />
                <SidebarFr pathname="fr-CA" />
              </div>
            </div>
          </div>
          <div className="sheet">
            {/* <Sheet /> */}
            <br />
            <p>
              Les données de l’IPP MLS® sont maintenant téléchargeables en
              format Excel (.xlsx).
              {/*  <a
                href="https://www.crea.ca/fr/housing-market-stats/mls-home-price-index/hpi-tool/"
                target="_blank"
              >
                <u>téléchargeables</u>
              </a>{' '} */}
            </p>
            <PopupDataHPI />
            <embed src={natl.hpi} width="960" height="970" />
          </div>
        </div>
        <Footersub />
        {/* <!--  ==========================================================  -->
  <!--  BOARD AND CREA DECLARATION HERE ==========================  -->
  <!--  ==========================================================  --> */}
        <div className={footerStyle.boardCredit}>
          <p>
            Les renseignements communiqués dans ce rapport ont été préparés par
            L’Association canadienne de l’immobilier (ACI) et proviennent de
            sources réputées fiables. L’ACI ne peut toutefois garantir
            l’exactitude et l’exhaustivité des renseignements et n’assume aucune
            responsabilité à cet égard. ©{this.getYear()} L’Association
            canadienne de l’immobilier. Tous droits réservés. Il est interdit de
            reproduire ces renseignements en tout ou en partie sans avoir obtenu
            l’autorisation écrite de l’ACI.
          </p>
        </div>
        {/* <!--  ==========================================================  -->
  <!-- END EDITING PAGE CONTENT HERE =============================  -->
  <!--  ==========================================================  --></div> */}
      </LayoutFr>
    )
  }
}

RootDEIndex.propTypes = propTypes

export default RootDEIndex

export const pageQuery = graphql`
  query HomeFRQuery {
    site {
      siteMetadata {
        title
        languages {
          defaultLangKey
          langs
        }
      }
    }
    fr: allContentfulNational(
      filter: { node_locale: { eq: "fr-CA" } }
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          id
          contentful_id
          node_locale
          headline
          slug
          hpi
          chartA {
            fixed(width: 1079, height: 740) {
              src
              width
              height
              srcSet
            }
          }
          chartA {
            fluid(maxWidth: 1079, maxHeight: 740) {
              aspectRatio
              srcSet
              src
            }
          }
          chartB {
            fixed(width: 1079, height: 740) {
              src
              width
              height
              srcSet
            }
          }
          chartB {
            fluid(maxWidth: 1079, maxHeight: 740) {
              aspectRatio
              srcSet
              src
            }
          }
          publishDate
          contentA {
            childContentfulRichText {
              html
            }
          }
          contentB {
            childContentfulRichText {
              html
            }
          }
          contentC {
            childContentfulRichText {
              html
            }
          }
          contentD {
            childContentfulRichText {
              html
            }
          }
        }
      }
    }
    frnatltitle: allContentfulStatsPages(filter: { title: { eq: "French" } }) {
      ...SourceY
    }
    frn: allContentfulStatsPages(filter: { node_locale: { eq: "fr-CA" } }) {
      ...SourceY
    }
  }
  fragment SourceY on ContentfulStatsPagesConnection {
    edges {
      node {
        title
        slug
        contentA {
          childContentfulRichText {
            html
          }
        }
        contentful_id
        node_locale
      }
    }
  }
`
