import React from 'react'
import mobileTabStyle from '../Mobile-Tab/mobile-tab.module.css'
import BurgerIcon from '../Mobile-menu/burgerIcon'
import Popup from 'reactjs-popup'
import Menu from '../Mobile-menu/mobile-menu-fr'
import '../Mobile-menu/mobile.menu.css'

const contentStyle = {
  background: 'rgba(255,255,255,0)',
  width: '80%',
  border: 'none',
  background: 'rgba(219,47,37,0.8)',
  padding: '0pm !important',
}
class MobileTabFR extends React.Component {
  render() {
    return (
      <div className={mobileTabStyle.stickyTab}>
        <Popup
          modal
          overlayStyle={{ background: 'rgba(219,47,37,0.98)' }}
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
          trigger={open => <BurgerIcon open={open} />}
        >
          {close => <Menu close={close} />}
        </Popup>
      </div>
    )
  }
}
export default MobileTabFR
