import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Accordion, AccordionItem } from 'react-sanfona'
import Lightbox from 'react-images'
import '../Chart/chart.module.css'
import Chart from '../Chart/chart.module.css'

class NRSFR extends React.Component {
  constructor(props) {
    super(props)

    this.toggle = this.toggle.bind(this)
    this.state = {
      activeTab: '1',
      activeClickedItems: [0],
      currentImage: 0,
      activeImagesAcc: null,
    }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)

    this.toggleActive = this.toggleActive.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  toggleActive(index) {
    const position = this.state.activeClickedItems.indexOf(index)

    if (position !== -1) {
      this.setState({ activeClickedItems: [] })
    } else {
      this.setState({ activeClickedItems: [index] })
    }
  }

  handleClick({ activeItems }) {
    this.setState({ activeClickedItems: activeItems })
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      })
    }
  }
  openLightbox(index, aImages) {
    this.setState({
      currentImage: index,
      lightboxIsOpen: true,
      activeImagesAcc: aImages,
    })
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    })
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    })
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    })
  }

  render() {
    const nrs = this.props.acc.natlResStats.edges

    const { activeTab } = this.state

    const nrsImages = nrs.map((item, id) => {
      return {
        src: item.node.childImageSharp.fluid.src,
        width: 247,
        height: 169,
      }
    })

    const activeImages = activeTab == '1' ? nrsImages : []

    return (
      <div>
        <Accordion>
          {['STATISTIQUES RÉSIDENTIELLES NATIONALES'].map(item => {
            return (
              <AccordionItem
                key={item}
                title={`${item}`}
                expanded={item === 'STATISTIQUES RÉSIDENTIELLES NATIONALES'}
              >
                <div className={Chart.chartAccordImg}>
                  {item === 'STATISTIQUES RÉSIDENTIELLES NATIONALES' ? (
                    <div>
                      {nrsImages.map((value, index) => (
                        <img
                          src={value.src}
                          onClick={() => this.openLightbox(index, nrsImages)}
                          key={index}
                          alt="STATISTIQUES RÉSIDENTIELLES NATIONALES"
                          title="STATISTIQUES RÉSIDENTIELLES NATIONALES"
                        />
                      ))}
                    </div>
                  ) : null}
                  {this.state.activeImagesAcc ? (
                    <Lightbox
                      images={this.state.activeImagesAcc}
                      onClose={this.closeLightbox}
                      onClickPrev={this.gotoPrevious}
                      onClickNext={this.gotoNext}
                      currentImage={this.state.currentImage}
                      isOpen={this.state.lightboxIsOpen}
                    />
                  ) : null}
                </div>
              </AccordionItem>
            )
          })}
        </Accordion>
      </div>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query NationalChartFRQuery {
        natlResStats: allFile(
          filter: { sourceInstanceName: { eq: "natlResStatsFR" } }
          sort: { fields: [name], order: ASC }
        ) {
          ...NationalFr
        }
      }

      fragment NationalFr on FileConnection {
        edges {
          node {
            name
            id
            sourceInstanceName
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
      }
    `}
    render={data => (
      <header>
        <NRSFR acc={data} />
      </header>
    )}
  />
)
