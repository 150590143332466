import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'

import footerStyle from '../Footer/footer.module.css'

class BAFR extends React.Component {
  render() {
    const BAtext = get(this, 'props.text.cafr')
    return (
      <div className={footerStyle.footer}>
        <div id="cc">
          <h3>
            <b>Remarque :</b>
          </h3>
          <div>
            <div
              className="content entry-sub"
              dangerouslySetInnerHTML={{
                __html:
                  BAtext.banumber.boardAndAccText.childMarkdownRemark.html,
              }}
            />
            {/* <div>
              <strong>
                <p>
                  Les renseignements contenus dans ce communiqué comprennent à
                  la fois les données des systèmes MLS® sur les principaux
                  marchés et sur les ventes réalisées à l’échelle nationale le
                  mois précédent.
                </p>
                <p>
                  L’ACI tient à signaler que le prix moyen peut servir à
                  déterminer les tendances au cours d’une période, mais qu’il ne
                  désigne pas les prix réels dans les centres composés de
                  quartiers très différents et qu’il ne tient pas compte des
                  différences de prix d’une région géographique à l’autre. Les
                  renseignements statistiques que renferme la présente analyse
                  comprennent toutes les catégories de propriétés.
                </p>
              </strong>
              <p>
                Les systèmes MLS® sont des systèmes de vente collaboratifs
                utilisés uniquement par les chambres immobilières canadiennes
                aux fins de la diffusion maximale des propriétés à vendre.
              </p>
              <p>
                L’Association canadienne de l’immobilier (ACI) est l’une des
                plus grandes associations à vocation unique au Canada et
                représente plus de 160 000 courtiers et agents immobiliers
                détenteurs du titre REALTOR® répartis parmi 65 chambres et
                associations immobilières.
              </p>
            </div> */}
          </div>
        </div>

        {/*         <!--  =========================================================== -->
<!--  BOARD CONTACT INFORMATION HERE ============================ -->
<!--  =========================================================== --> */}
        <div className="board_contact">
          <p>
            {/*  <strong>
              Pour plus amples renseignements, prière de communiquer avec :
            </strong>{' '} */}
            <br />
            <strong>Médias seulement :</strong> <br />
            Pierre Leduc
            <br />
            Relations auprès des médias
            <br />
            Courriel : 613-237-7111 or 613-884-1460
            <br />
            Courriel&nbsp;:&nbsp;
            <a
              href="mailto:pleduc@crea.ca"
              className="emailcolor"
              alt="pleduc@crea.ca"
              title="pleduc@crea.ca"
            >
              <span className="emailcolor">pleduc@crea.ca</span>
            </a>
            <br />
            <br />
            <strong>Toute autre demande : </strong> <br />
            Jacob Coopersmith
            <br />
            Coordonnateur, Statistiques MLS®
            <br />
            Courriel&nbsp;:&nbsp;
            <a
              href="mailto:jcoopersmith@crea.ca"
              className="emailcolor"
              alt="jcoopersmith@crea.ca"
              title="jcoopersmith@crea.ca"
            >
              <span className="emailcolor">jcoopersmith@crea.ca</span>
            </a>
          </p>
          <img
            src={require('../../images/mls_logo.png')}
            width="130"
            height="64"
            alt="MLS Logo"
            title="MLS Logo"
          />
          <hr />
        </div>
      </div>
    )
  }
}
// export default FooterSub

export default props => (
  <StaticQuery
    query={graphql`
      query {
        cafr: contentfulNational(node_locale: { eq: "fr-CA" }) {
          title
          node_locale
          banumber {
            boardAndAccText {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `}
    render={data => (
      <div>
        <BAFR text={data} />
      </div>
    )}
  />
)
